<template>
    <div class="">
        <layout class="xs-bg-light" :xswhite="true">
            <template v-slot:main>
                <div class="white">
                    <v-container class="">
                        <breadcrumbs :items="breadcrumbs" :large="true" class="py-7 d-none d-md-block"></breadcrumbs>
                        <router-link :to="{ name: 'frontend.careers', params: {} }" class="d-flex align-center blue-text d-lg-none mt-5 mb-3"><v-icon class="mr-5 blue-text">mdi-chevron-left</v-icon> Back to all vacancies</router-link>
                        <div class="article d-flex mb-10">
                            <div class="share mr-12 d-none d-md-block">
                                <div class="mb-3">
                                    <v-hover v-slot="{ hover }">
                                        <div class="share-circle d-flex align-self-center justify-center">
                                            <img v-show="!hover" class="share-icon" src="@/assets/icons/svg/facebook-dark.svg" alt="">
                                            <img v-show="hover" class="share-icon" src="@/assets/icons/svg/facebook-white.svg" alt="">
                                        </div>
                                    </v-hover>
                                </div>
                                <div class="mb-3">
                                    <v-hover v-slot="{ hover }">
                                        <div class="share-circle d-flex align-self-center justify-center">
                                            <img v-show="!hover" class="share-icon" src="@/assets/icons/svg/twitter-dark.svg" alt="">
                                            <img v-show="hover" class="share-icon" src="@/assets/icons/svg/twitter-white.svg" alt="">
                                        </div>
                                    </v-hover>
                                </div>
                                <div class="mb-3">
                                    <v-hover v-slot="{ hover }">
                                        <div class="share-circle d-flex align-self-center justify-center">
                                            <img v-show="!hover" class="share-icon" src="@/assets/icons/svg/mail-dark.svg" alt="">
                                            <img v-show="hover" class="share-icon" src="@/assets/icons/svg/mail-white.svg" alt="">
                                        </div>
                                    </v-hover>
                                </div>
                                <div class="mb-3">
                                    <v-hover v-slot="{ hover }">
                                        <div class="share-circle d-flex align-self-center justify-center">
                                            <img v-show="!hover" class="share-icon" src="@/assets/icons/svg/link-dark.svg" alt="">
                                            <img v-show="hover" class="share-icon" src="@/assets/icons/svg/link-white.svg" alt="">
                                        </div>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="main">
                                <v-row class="pt-0">
                                    <v-col cols="12" lg="8" class="pt-0">
                                        <h1 class="heading-2 mb-8">{{item.title}}</h1>
                                        <p class="">{{item.subtitle}}</p>
                                        <v-divider class="mt-7 my-md-10"></v-divider>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-0">
                                    <v-col cols="12" lg="3">
                                        <div class="text-20-60">
                                            Responsibilities:
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="pt-0">
                                        <v-list flat :ripple="false" class="pt-0">
                                            <v-list-item
                                            v-for="(item, i) in item.responsibilities"
                                            :key="i"
                                            >
                                                <v-list-item-content>
                                                    <div class="d-flex">
                                                        <img src="@/assets/icons/svg/dot-blue.svg" class="mr-5" alt="">
                                                        <v-list-item-title class="text-16-45" v-html="item.text"></v-list-item-title>
                                                    </div>
                                                    <div class="text-16 w-50 subtext ml-7" v-text="item.subtext"></div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="mt-5">
                                        <div class="text-20-60">
                                            Required Qualifications:
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="mt-md-5 pt-0">
                                        <v-list flat :ripple="false" class="pt-0">
                                            <v-list-item
                                            v-for="(item, i) in item.qualification"
                                            :key="i"
                                            >
                                                <img src="@/assets/icons/svg/dot-blue.svg" class="mr-8" alt="">
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-16-45" v-html="item.text"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-container>
                </div>
                <div class="bg-dark py-4 py-md-16">
                    <v-container>
                        <div class="text-26-45 white--text">
                            If you are motivated, hard-working and result-driven don’t hesitate to contact us by email: <a href="mailto:hr@freightcrew.us" class="text-decoration-none">hr@freightcrew.us</a>
                        </div>
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue';
import Breadcrumbs from '@/components/base/Breadcrumbs.vue';



export default {
    components: {
        Layout,
        Breadcrumbs,

    },
    data: () => ({
        item:{
            title: 'Transportation Supervisor',
            subtitle: 'As the Transportation Supervisor of the capacity team, your daily tasks will include supervising employee performance, allocating driver assignments, ensuring compliance with safety standards, and maintaining all vehicles in top working order. You will manage databases that detail transport activities, financial accountings, employee schedules and personnel records',
            responsibilities: [
                {
                    text: 'Ensure implementation day-to-day activities of transportation'
                },
                {
                    text: 'Supervise workloads, schedule, tasks and other related functions'
                },
                {
                    text: 'Ensure compliance of safety standards in transportation functions',
                    subtext: 'Ensure maintenance of vehicles in good running condition. Ensure compliance of safety standards in transportation functions'
                },
                {
                    text: 'Build internal and external customer relationships'
                },
                {
                    text: 'Fluent in English'
                }
            ],
            qualification: [
                {
                    text: 'Fluent in English'
                },
                {
                    text: 'Ability to work in a team environment, while <br/> also delivering independent results'
                },
                {
                    text: 'Strong ability to persuade, motivate, and influence others'
                },
                {
                    text: 'Analytical & process oriented'
                },
            ]
        },
        breadcrumbs: []
    }),
    mounted() {
        this.breadcrumbs.push({
            text: 'Home page',
            to: 'frontend.home',
            disabled: false,
        },
        {
            text: 'Careers',
            to: 'frontend.careers',
            disabled: false,
        },
        {
            text: this.item.title,
            to: 'frontend.careers',
            disabled: true,
        })
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
    .article{
        .share{
            .share-circle{
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background: $silver_first;
                border: 1px solid $silver_first;
                img{
                    max-width: 50%;
                    max-height: 50%;
                    margin: auto;
                }
            }
            .share-circle:hover{
                background: #5496F0;
                border: 1px solid $blue_first;
            }
        }
        .subtext{
            flex: none;
        }
        .v-list-item__title, .v-list-item__subtitle{
            white-space: normal;
        }
        .v-divider{
            border-color: #CDE0FF;
        }
        @media all and (max-width:600px){
            .w-50{
                width: 100%;
            }
            .v-list-item__content > *{
                flex: auto;
            }
        }
    }
</style>
